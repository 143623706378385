<template>
  <app-module-view>
    <template slot="body">
      <section class="m-b-10">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-right m-r-5">
              <button
                v-if="isSaveButtonVisible"
                type="button"
                class="btn btn-success"
                @click="save"
              >
                <i class="fa fa-save "></i> {{ $t('buttons.save') }}
              </button>
              <app-button-delete v-if="isDeleteButtonVisible" @deleteRecord="deleteDepartment" />
              <app-button-close route-name="department_list"></app-button-close>
            </div>
          </div>
        </div>
      </section>
      <div class="card">
        <div class="card-body">
          <section class="content form-horizontal">
            <div class="box box-info">
              <div class="box-body">
                <div class="row">
                  <div class="col-lg-4">
                    <input type="hidden" v-model="department.id">
                    <app-input
                      v-model="department.name"
                      @blur="$v.department.name.$touch()"
                      :error="$v.department.name.$error"
                      id="department_name"
                      :label="$t('department.name')"
                    >
                    </app-input>
                  </div>
                  <div class="col-lg-4">
                    <app-select
                      v-model="department.site"
                      @blur="$v.department.site.$touch()"
                      :error="$v.department.site.$error"
                      :options="enabledSites"
                      id="department_site"
                      :label="$t('department.site')"
                    >
                    </app-select>
                  </div>
                  <div class="col-lg-4">
                    <label>{{ $t('department.managers') }}</label>
                    <app-multi-select
                      v-model="departmentManagers"
                      :options="managers"
                      label="username"
                      track-by="id"
                      id="department_managers"
                    >
                    </app-multi-select>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../ModuleView'
import Input from '../form/inputs/Input'
import Select from '../form/select/Select'
import MultiSelect from '../form/select/MultiSelect'
import { maxLength, minLength, required } from 'vuelidate/lib/validators'
import DepartmentModel from '../../model/Department'
import ButtonDelete from '../shared/ButtonDelete'
import ButtonClose from '../shared/ButtonClose'
import NotifyService from '../../services/NotifyService'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'DepartmentNew',
  data () {
    return {
      dataLoaded: false,
      isLoading: false,
      department: this._.cloneDeep(DepartmentModel),
      departmentManagers: [],
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.SYSTEM_DEPARTMENT_PERMISSIONS
    }
  },
  validations: {
    department: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(100)
      },
      site: {
        required
      }
    }
  },
  components: {
    appModuleView: ModuleView,
    appInput: Input,
    appSelect: Select,
    appMultiSelect: MultiSelect,
    appButtonDelete: ButtonDelete,
    appButtonClose: ButtonClose
  },
  computed: {
    isDeleteButtonVisible () {
      return this.department?.id && this.hasPermission(this.requiredPermissions.deleteButton)
    },
    isSaveButtonVisible () {
      return this.hasPermission(this.requiredPermissions.saveButton)
    },
    managers () {
      return this.$store.getters['user/all']
    },
    enabledSites () {
      return this.$store.getters['site/allEnabledSorted']()
    }
  },
  methods: {
    hasPermission (permission) {
      return this.$store.getters['user/hasPermission'](permission)
    },
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
      } else {
        this.$store.dispatch('department/create', this.department)
          .then(() => {
            if (this.$store.getters['department/error'] === null) {
              NotifyService.setSuccessMessage(this.$t('notify.record_was_created'))
              this.department = this.$store.getters['department/detail']
              this.$store.dispatch('department/fetchAll')
              this.$router.push('/department/' + this.department.id + '/edit')
            } else {
              NotifyService.setErrorMessage(this.$store.getters['department/error'])
            }
          })
          .catch(error => console.log(error))
      }
    },
    deleteDepartment () {
      this.$store.dispatch('department/deleteRecord', this.department)
        .then(() => {
          if (this.$store.getters['department/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_deleted'))
            this.$router.push('/department')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['department/error'])
          }
        })
        .catch(error => console.log(error))
    }
  },
  watch: {
    departmentManagers () {
      this.department.managers = this.departmentManagers.map(function (departmentManager) {
        return departmentManager.id
      })
    }
  }
}
</script>
