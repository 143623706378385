<template>
    <span class="root">
        <button
          :disabled="disabled"
          type="button"
          class="btn btn-danger btn-delete-row"
          :class="btnClass"
          :title="$t('buttons.delete_button')"
          @click="showDeleteModal"
        >
            <i class="fa fa-trash"></i> <span class="hidden-sm-down">{{ $t('buttons.delete_button') }}</span>
        </button>

        <div class="article-usage" v-if="disabled && tooltip.length">
            <span class="label label-warning p-1">
                <i class="fas fa-info-circle"></i> {{ tooltip }}
            </span>
        </div>

        <app-modal
          v-if="deleteModal"
          modal-size="modal-md"
          :title="$t('modal.delete_modal_header')"
          class="confirm-delete-modal"
          @close="closeDeleteModal"
        >
            <!-- Body -->
            <template slot="body">
                {{ deleteModalMessage }}
            </template>

          <!-- Footer -->
            <template slot="footer">
                <button
                  type="button"
                  class="btn btn-inverse router-link-active"
                  @click="closeDeleteModal"
                >
                    {{ $t('modal.delete_modal_cancel_btn') }}
                </button>
                <button
                  type="button"
                  class="btn btn-danger"
                  @click="deleteRecord"
                >
                    {{ $t('modal.delete_modal_confirm_btn') }}
                </button>
            </template>
        </app-modal>
    </span>
</template>

<script>
import Modal from '../shared/Modal'

export default {
  name: 'ButtonDelete',
  components: {
    appModal: Modal
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      default: ''
    },
    recordId: {
      type: Number,
      default: 0
    },
    btnClass: {
      type: String,
      default: ''
    },
    sourceType: {
      type: String, // tag, special, article etc... (optional prop)
      default: null
    }
  },
  data () {
    return {
      deleteModal: false
    }
  },
  computed: {
    deleteModalMessage () {
      switch (this.sourceType) {
        case 'tag':
          return this.$t('modal.tag_delete_modal_text')
        default:
          return this.$t('modal.delete_modal_text')
      }
    }
  },
  methods: {
    showDeleteModal () {
      this.deleteModal = true
    },
    closeDeleteModal () {
      this.deleteModal = false
    },
    deleteRecord () {
      this.$emit('deleteRecord', this.recordId)
    }
  }
}
</script>

<style lang="scss" scoped>
.root {
  position: relative;
}

.confirm-delete-modal {
  text-align: center;
}

.article-usage {
  position: absolute;
  right: 0;
  white-space: nowrap;
  z-index: 1000;
  opacity: 0;
  transition: opacity .2s ease-in-out;
  pointer-events: none;
}

.root:hover .article-usage {
  opacity: 1;
}
</style>
